<template>
  <div class="custom-bg-GIT ">
    <div class="row  auto-lr-gettouch">
      <div class="col-12 col-md-6 ">
        <div class=" respone-txt ml-5 my-4 ">
          <div class="big-text-gettouch">
            <span>{{ $t("message.content25") }}</span>
          </div>
          <div class="small-text-gettouch">
            <p>
              {{ $t("message.content26") }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 respone-btn  my-4">
        <a href="/contact">
          <button
            @click="action_button((activeBtn = 'btn6'))"
            class="custom-btn-get text-btn-get"
          >
            {{ $t("message.content27") }}
          </button>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    action_button(activeBtn) {
      localStorage.setItem("activeBtn_set", JSON.stringify(activeBtn));
      // localStorage.clear();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/get-in-touch";
@import "@/assets/scss/main";
</style>
