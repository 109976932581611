<template>
  <div class="container-fluid">
    <b-row class=" justify-content-center">
      <b-col cols="12" md="3" class="p-0">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase1.png"
        />
      </b-col>
      <b-col cols="12" md="3" class="p-0">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase2.png"
        />
      </b-col>

      <b-col cols="12" md="3" class="p-0 custom-d-none">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase3.png"
        />
      </b-col>
      <b-col cols="12" md="3" class="p-0 custom-d-none">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase4.png"
        />
      </b-col>
    </b-row>

    <b-row class="justify-content-md-center ">
      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center align-items-center"
      >
        <div class="container mx-4 my-4">
          <div class="custom-borderline mb-4">
            <p class="text-title-sc">{{ $t("message.content41") }}</p>
          </div>

          <span class="text-title-sub-sc">
            {{ $t("message.content42") }}
          </span>
          <br />
                      
          <a
            href="mailto:neen@mondail.co.th"
            class="text-title-sub-bule-sc"
            >{{ $t("message.content43") }}</a
          >
          <!-- <span
            class="text-title-sub-bule-sc"
            @click="sendMail()"
            ><u>{{ $t("message.content43") }}</u></span> -->
          <!-- <a
            href="https://th1337639403eyem.trustpass.alibaba.com/"
            class="text-title-sub-bule-sc"
            >{{ $t("message.content43") }}</a
          > -->
        </div>
      </b-col>

      <b-col cols="12" md="3" class="p-0 custom-d-none">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase3.png"
        />
      </b-col>
      <b-col cols="12" md="3" class="p-0 custom-d-none">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase5.png"
        />
      </b-col>
    </b-row>

    <b-row class="justify-content-md-center custom-d-none">
      <b-col cols="12" md="3" class="p-0">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase6.png"
        />
      </b-col>
      <b-col cols="12" md="3" class="p-0">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase7.png"
        />
      </b-col>
      <b-col cols="12" md="3" class="p-0">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase8.png"
        />
      </b-col>
      <b-col cols="12" md="3" class="p-0">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase9.png"
        />
      </b-col>
    </b-row>

    <b-row class="justify-content-md-center custom-d-none">
      <b-col cols="12" md="3" class="p-0">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase9.png"
        />
      </b-col>
      <b-col cols="12" md="3" class="p-0">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase10.png"
        />
      </b-col>
      <b-col cols="12" md="3" class="p-0">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase11.png"
        />
      </b-col>
      <b-col cols="12" md="3" class="p-0">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase12.png"
        />
      </b-col>
    </b-row>

    <b-row class="justify-content-md-center custom-d-none-web">
      <b-col cols="12" md="3" class="p-0">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase3.png"
        />
      </b-col>
      <b-col cols="12" md="3" class="p-0">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase4.png"
        />
      </b-col>
      <b-col cols="12" md="3" class="p-0">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase3.png"
        />
      </b-col>
      <b-col cols="12" md="3" class="p-0">
        <img
          class="img-respone-sc"
          src="@/assets/image-showcase/image_showcase5.png"
        />
      </b-col>
    </b-row>
    <div class="row">
      <get-tounch />
    </div>
  </div>
</template>

<script>
import Gettounch from "@/components/Gettouch.vue";

export default {
  components: { "get-tounch": Gettounch },
  methods: {
    sendMail() {
      window.open(
        `mailto:neen@mondial.co.th?&body=${"Fullname : " +
          "%0A" +
          "Email Address : " +
          "%0A" +
          "Phone Number : " +
          "%0A" +
          "Message : "}
        `
      );
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/home";
@import "@/assets/scss/showcase";
</style>
